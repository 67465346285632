body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table-input {
  width: 125px !important;
}

.ui.selection.dropdown.table-dropdown { min-width: 125px !important; }

.appBody {
  padding-top: 100px;
  padding-left: 20px;
}

.table-container {
  /* height: 290px; */
  overflow-y: auto; 
  overflow-x: auto; /* or auto */
}

.p-t-15 {
  padding-top: 15px;
}

.p-t-25 {
  padding-top: 25px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-t-25 {
  margin-top: 25px;
}


.m-l-10 {
  margin-left: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.text-white {
  color: #FFFFFF;
}
.text-grey {
  color: #808080;
}
.text-black {
  color: #1B1C1D;
}
.text-yellow {
  color: #F2C61F;
}
.text-teal {
  color: #00B5AD;
}
.text-red {
  color: #D95C5C;
}
.text-purple {
  color: #564F8A;
}
.text-pink {
  color: #D9499A;
}
.text-orange {
  color: #E07B53;
}
.text-green {
  color: #5BBD72;
}
.text-blue {
  color: #3B83C0;
}